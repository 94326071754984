@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.notification {
  animation-duration: 0.1s; /* 1.5 seconds for fade-in */
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: fixed;
  opacity: 0;
}

/* Apply fadeIn animation to fade-in class */
.fade-in {
  animation-name: fadeIn;
  opacity: 1;
}

/* Apply fadeInOut animation to fade-out class */
.fade-out {
  animation-name: fadeInOut;
  opacity: 0;
}

.animate-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid;
  border-image: linear-gradient(45deg, gold, deeppink) 1;
  animation: huerotate 1.5s infinite linear;
  filter: hue-rotate(360deg);
}

@keyframes huerotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #b9feff;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#46eaec, #52f4b0, #9affe4);
  border-radius: 10px;
}

.MuiCardHeader-title {
  font-family: Pixelify Sans, sans-serif !important;
}
