.egg-crack-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.egg {
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.egg.cracking {
  animation: shake 0.5s ease-in-out infinite;
}

.egg-enter {
  opacity: 0;
  transform: scale(0.9);
}

.egg-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s, transform 0.5s;
}

.egg-exit {
  opacity: 1;
}

.egg-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
}
