* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
}
.section {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h2 {
  font-family: "Pixelify Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.center-all {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center-all h5 {
  font-family: "Protest Riot", sans-serif;
  font-weight: 400;
}

.about-section {
  background-color: #1a1a80;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}
.about-section h2 {
  padding-top: 50px;
  font-size: 40px;
  font-family: poppins;
}

.about-container {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-container h5 {
  font-size: 1.5em;
  margin-bottom: 2em;
  font-family: "Protest Riot", sans-serif;
  font-weight: 400;
}

.about-section .icons img {
  width: 100px;
  height: auto;
  margin: 0 10px;
  border: 1px solid black;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.6);
}

.play-now-button {
  background-color: #5cb85c;
  color: white;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2rem;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s;
}

.header-scrolled {
  background-color: rgba(112, 171, 247, 0.8);
}

header img {
  height: 50px;
}
nav {
  display: flex;
  align-items: center;
}
.menu,
.menu-toggle {
  display: none;
}
nav a,
.play-now {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: "Pixelify Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

nav a.active,
.play-now.active {
  color: #1ef70a;
  text-decoration: underline;
}
.play-now {
  background-color: #5cb85c;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
footer {
  background: url("../../public/assets/landing_page_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  text-align: center;
  padding: 1rem;
}
@media (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
  }
  .menu.active {
    display: flex;
  }
  .menu-toggle {
    display: block;
    cursor: pointer;
  }
  nav a,
  .play-now {
    display: block;
    text-align: left;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .skills-content {
    flex-direction: column;
  }

  .skills-text,
  .skills-cards {
    width: 100%;
  }

  .skill-icon {
    position: static;
    width: 100px;
    margin: 1rem 0;
  }
}

/* App.css */
.logo {
  height: 50px;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu a,
.play-now {
  text-decoration: none;
  color: black;
  padding: 0.5rem 1rem;
}

.play-now {
  background-color: #5cb85c;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.menu-toggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .menu.expanded {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(115, 175, 253, 0.8);
    margin-top: 22px;
  }

  .menu-toggle {
    display: block;
  }
}

@media (max-width: 768px) {
  .about-section .icons img {
    width: 80px;
  }
  .about-section h2 {
    padding-bottom: 20px;
    line-height: 60px;
  }
}

#home {
  background: url("../../public/assets/landing_page_bg.webp") no-repeat center
    center fixed;
  background-size: cover;
}

#about {
  background: url("../../public/assets/landingpage/about-hoarding-life.webp")
    no-repeat center center fixed;
  background-size: cover;
}

#skills {
  transition: background-image 0.5s ease-in-out;
  background: url("../../public/assets/landingpage/gatherskill-bg.webp")
    no-repeat center center fixed;
  background-size: cover;
}

#info {
  background: url("../../public/assets/landingpage/collect-them-all.webp")
    no-repeat center center fixed;
  background-size: cover;
}

/* new cards stack */
.center {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.stack-area {
  width: 100%;
  height: 300vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.right,
.left {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  flex-basis: 40%;
}

.left {
  align-items: center;
  flex-direction: column;
}

.leftCard {
  max-width: 350px;
}

.cards {
  width: 100%;
  height: 100%;
  position: relative;
}
.card {
  width: 300px;
  height: 150px;
  box-sizing: border-box;
  padding: 35px;
  border-radius: 6mm;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.5s ease-in-out;
  border: 1px solid black;
}
.card:nth-child(1) {
  background-image: url("../../public/assets/cards/gather_card.webp");
  background-size: 100% 100%;
  z-index: 4;
}
.card:nth-child(2) {
  background-image: url("../../public/assets/cards/process_card.webp");
  background-size: 100% 100%;
  z-index: 3;
}
.card:nth-child(3) {
  background-image: url("../../public/assets/cards/craft_card.webp");
  background-size: 100% 100%;
  z-index: 2;
}
.card:nth-child(4) {
  background-image: url("../../public/assets/cards/fish_card.webp");
  background-size: 100% 100%;
  z-index: 1;
}
.sub {
  font-family: poppins;
  font-size: 20px;
  font-weight: 700;
}
.content {
  font-family: poppins;
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
}
.card.active {
  transform-origin: bottom left;
}
.title {
  font-size: 50px;
  font-family: poppins;
  font-weight: 700;
  line-height: 88px;
  color: rgb(62, 177, 243);
  text-align: center;
  font-family: "Pixelify Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.sub-title {
  font-family: poppins;
  font-size: 14px;
  margin-top: 30px;
  width: 350px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  text-align: center;
}
.top,
.bottom {
  width: 100%;
  height: 100vh;
  font-family: poppins;
  font-size: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardImage {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .leftCard {
    margin-top: 150px;
  }

  .title {
    font-size: 6vw;
    line-height: 7vw;
    margin: auto;
  }

  .sub-title {
    font-size: 4vw;
    width: 60%;
    margin: auto;
  }

  .card {
    width: 300px;
    height: 150px;
  }
}

/* .info-section {
  background-color: #1a1a80;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  position: relative;
}

.info-container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(58, 239, 245, 0.6);
  height: 600px;
  padding: 40px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.info-section h2 {
  padding-top: 50px;
  font-size: 40px;
  font-family: poppins;
} */

.info-section {
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  position: relative;
  padding: 50px 20px;
}

.info-container {
  max-width: 800px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-container p {
  font-size: 20px;
  margin-bottom: 200px;
  font-family: "Protest Riot", sans-serif;
  font-weight: 400;
  line-height: 4vh;
}

.info-section h2 {
  font-size: 40px;
  padding-top: 50px;
}

/* Add additional styles here for p tags and other elements inside .info-container */
