/* Inventory.css */
.inventory-container {
  display: flex; /* Align grid and action panel side by side */
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Dark background for the inventory area */
  padding: 20px;
  align-items: flex-start; /* Align items to the top */
  overflow-y: auto;
  max-height: 650px;
}

.inventory-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(50px, 1fr)
  ); /* Adjust the minmax value to control the size of the squares */
  grid-gap: 10px; /* Space between items */
  flex-grow: 1; /* Allow grid to take up available space */
  margin-bottom: 20px;
}

.inventory-item {
  border: 1px solid #777; /* Border for the items */
  border-radius: 5px; /* Rounded corners for the items */
  overflow: hidden; /* Ensures content fits within the borders */
  position: relative; /* For absolute positioning of item details if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6); /* Background for items */
}

.inventory-item img {
  max-width: 100%; /* Ensure image doesn't overflow its container */
  max-height: 100vh;
}

.action-panel {
  width: 200px; /* Fixed width for the action panel */
  margin-left: 20px; /* Space between the grid and action panel */
  background-color: #222; /* Background for the action panel */
  padding: 10px;
  border: 1px solid #777; /* Border for the action panel */
  border-radius: 5px; /* Rounded corners for the action panel */
}

/* Hover effect on items */
.inventory-item:hover {
  border-color: #aaa; /* Lighter border on hover */
}

/* Item Details Pane */
.item-details {
  width: 300px; /* Fixed width for the details pane */
  border: 1px solid #777; /* Border for the details pane */
  border-radius: 5px; /* Rounded corners for the details pane */
  padding: 10px;
  background-color: transparent; /* Details pane background color */
}

.item-details h3 {
  margin-top: 0;
  color: #4caf50; /* Color for the item name or header */
}

/* Button Styles */
.button {
  padding: 5px 10px;
  margin: 5px;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #4caf50; /* Green background for buttons */
  cursor: pointer;
}

.button:hover {
  background-color: #367c39; /* Darker shade on hover */
}

/* Input and Sell Section */
.sell-section {
  align-items: center;
  margin-top: 10px;
}

.sell-section .sell-button {
  background-color: #f44336; /* Red background for sell button */
}

.sell-button:hover {
  background-color: #c1352d; /* Darker shade on hover */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .inventory-container {
    flex-direction: column;
  }

  .item-details {
    width: 300px; /* Full width on smaller screens */
    margin: auto;
  }
}

/* Additional classes for specific elements like Upgrade, Equip sections can be added as needed */
