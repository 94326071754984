@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* > * {
  font-family: "Poppins", cursive;
}

.board {
  text-align: center;
  margin-bottom: 1em;
}

#profile {
  margin-top: 3em;
}

#profile .flex {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  text-align: left;
}

#profile .flex .item {
  display: flex;
  align-items: center;
}

#profile .flex .info {
  padding: 1em;
}

.leaderboard {
  border-radius: 25px;
  color: black;
  margin: auto;
  padding: 5px;
  position: relative;
}

.top-profiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Top 1 profile styling */
.top-1 {
  z-index: 2;
}

/* Top 2 and 3 profiles container styling */
.sub-top-profiles {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 30%;
}

.user-info {
  text-align: center;
}

.name {
  margin-top: 5px;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .sub-top-profiles {
    justify-content: space-around;
  }
  #profile .flex {
    display: flex;
    justify-content: space-around;
    gap: 1em;
    text-align: left;
  }
}

.countdown-container {
  display: flex;
  width: 100%;
  max-width: 70%;
  justify-content: space-between;
}

.days-container,
.hours-container,
.minutes-container,
.seconds-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border: 5px solid rgba(255, 255, 255, 0.3);
  width: 140px;
  height: 140px;
  border-radius: 99px;
}

.days,
.hours,
.minutes,
.seconds {
  font-size: 2.5em;
  margin: 10px 0;
}

.days-label,
.hours-label,
.minutes-label,
.seconds-label {
  text-transform: uppercase;
  margin-bottom: 5px;
}

@media (max-width: 800px) {
  .countdown-container {
    max-width: 90%;
  }

  .days-container,
  .hours-container,
  .minutes-container,
  .seconds-container {
    font-size: 0.8em;
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 1024px) {
  .countdown-container {
    max-width: 30%;
  }

  .days-container,
  .hours-container,
  .minutes-container,
  .seconds-container {
    font-size: 0.8em;
    width: 100px;
    height: 100px;
  }
}